<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="stroke-black"
  >
    <path
      d="M2.66536 3.33325H13.332C14.0654 3.33325 14.6654 3.93325 14.6654 4.66659V11.3333C14.6654 12.0666 14.0654 12.6666 13.332 12.6666H2.66536C1.93203 12.6666 1.33203 12.0666 1.33203 11.3333V4.66659C1.33203 3.93325 1.93203 3.33325 2.66536 3.33325Z"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.6654 4.66675L7.9987 9.33341L1.33203 4.66675"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
